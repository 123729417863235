/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import React, { ReactElement, useState, useRef } from 'react';

import { types, enums } from '@koeajacom/ka-types';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';

import { FaCalendar, FaFloppyDisk } from 'react-icons/fa6';

import AgentAPI from '../../api/agent';
import ActionSpinner from '../common/ActionSpinner';

interface AddVehicleModalProps {
  show: boolean;
  vehicle: types.DBVehicle | null;
  api: AgentAPI;
  setVehicles: (vehicles: types.DBVehicle[]) => void;
  handleClose: () => void;
}

const AddVehicleModal = ({
  show,
  vehicle,
  api,
  setVehicles,
  handleClose,
}: AddVehicleModalProps): ReactElement => {
  const [vehicleTypeCode, setVehicleTypeCode] = useState<string>('1');
  const [traficomPending, setTraficomPending] = useState<boolean>(false);
  const [savePending, setSavePending] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);

  const [licensePlate, setLicensePlate] = useState<string>(vehicle ? vehicle.licensePlate : '');
  const [vin, setVin] = useState<string>(vehicle ? vehicle.vin : '');
  const [vehicleType, setVehicleType] = useState<string>(vehicle ? vehicle.vehicleType : 'M1');
  const [make, setMake] = useState<string>(vehicle ? vehicle.make : '');
  const [model, setModel] = useState<string>(vehicle ? vehicle.model : '');
  const [trim, setTrim] = useState<string>(vehicle ? vehicle.trim : '');
  const [color, setColor] = useState<string>(vehicle ? vehicle.color : '');
  const [nextInspectionDate, setNextInspectionDate] = useState<string | null>(vehicle ? vehicle.nextInspectionDate : null);
  const [nextInspectionDateUnknown, setNextInspectionDateUnknown] = useState<boolean>(!(vehicle && vehicle.nextInspectionDate));
  const [driversLicenseType, setDriversLicenseType] = useState<string>(vehicle ? vehicle.driversLicenseType : 'B');
  const [disabled, setDisabled] = useState<boolean>(vehicle ? vehicle.disabled : false);

  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === true) {
      setSavePending(true);

      const v: types.NewOrUpdatedVehicle = {
        licensePlate,
        vin,
        vehicleType: enums.VehicleType[vehicleType as keyof typeof enums.VehicleType],
        make,
        model,
        trim,
        color,
        nextInspectionDate: nextInspectionDateUnknown ? null : nextInspectionDate,
        driversLicenseType: enums.DriversLicenseType[driversLicenseType as keyof typeof enums.DriversLicenseType],
        disabled,
      };

      if (vehicle) {
        api.updateVehicle(vehicle.ID, v)
          .then((vehicles) => {
            setVehicles(vehicles.vehicles);
            handleClose();
          })
          .catch(() => setSavePending(false));
      } else {
        api.addVehicle(v)
          .then((vehicles) => {
            setVehicles(vehicles.vehicles);
            handleClose();
          })
          .catch(() => setSavePending(false));
      }
    }

    setValidated(true);
  };

  const parseTraficomVehicle = (v: types.VehicleRegInfo) => {
    setLicensePlate(v.licensePlate);
    setVin(v.vin);
    setVehicleType(v.vehicleType);
    setMake(v.make);
    setModel(v.model);
    setTrim(v.trim);
    setColor(v.color);
    setNextInspectionDate(v.nextInspectionDate);
    setNextInspectionDateUnknown(!v.nextInspectionDate);
    setDriversLicenseType(v.driversLicenseType);
    setTraficomPending(false);
  };

  const fetchFromTraficom = () => {
    setTraficomPending(true);
    if (licensePlate) {
      api.getVehicleRegInfoByLicensePlate(vehicleTypeCode, licensePlate)
        .then(parseTraficomVehicle).catch(() => { setTraficomPending(false); });
    } else {
      api.getVehicleRegInfoByVIN(vehicleTypeCode, vin)
        .then(parseTraficomVehicle).catch(() => { setTraficomPending(false); });
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="modal-wide"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <b>
            {vehicle ? t('agent-addVehicleModal-title-edit') : t('agent-addVehicleModal-title-new')}
            {' '}
            {vehicle?.licensePlate}
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          validated={validated}
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <Row>
            <Col xs={12} sm={6} lg={vehicle ? 6 : 3} className="mb-3">
              <Form.Group>
                <Form.Label>{t('general-vehicle-licensePlate')}</Form.Label>
                <Form.Control type="input" value={licensePlate} onChange={(e) => setLicensePlate(e.target.value)} required autoFocus />
              </Form.Group>
            </Col>
            <Col xs={12} sm={6} lg={vehicle ? 6 : 4} className="mb-3">
              <Form.Group>
                <Form.Label>{t('general-vehicle-vin')}</Form.Label>
                <Form.Control type="input" value={vin} onChange={(e) => setVin(e.target.value)} required />
              </Form.Group>
            </Col>
            {!vehicle
              && (
                <>
                  <Col xs={8} lg={3} className="mb-3 form-same-row">
                    <Form.Group>
                      <Form.Label>{t('agent-addVehicleModal-form-title-vehicleTypeCode')}</Form.Label>
                      <Form.Select
                        value={vehicleTypeCode}
                        onChange={(e) => setVehicleTypeCode(e.target.value)}
                        disabled={(!!licensePlate && !!vin) || (!licensePlate && !vin)}
                      >
                        <option value="1">{t('agent-addVehicleModal-form-select-vehicleTypeCode-1')}</option>
                        <option value="2">{t('agent-addVehicleModal-form-select-vehicleTypeCode-2')}</option>
                        <option value="3">{t('agent-addVehicleModal-form-select-vehicleTypeCode-3')}</option>
                        <option value="4">{t('agent-addVehicleModal-form-select-vehicleTypeCode-4')}</option>
                        <option value="5">{t('agent-addVehicleModal-form-select-vehicleTypeCode-5')}</option>
                        <option value="7">{t('agent-addVehicleModal-form-select-vehicleTypeCode-7')}</option>
                        <option value="8">{t('agent-addVehicleModal-form-select-vehicleTypeCode-8')}</option>
                        <option value="0">{t('agent-addVehicleModal-form-select-vehicleTypeCode-0')}</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs={4} lg={2} className="mb-3">
                    <Form.Group>
                      <Form.Label>{t('agent-addVehicleModal-form-title-getFromTraficom')}</Form.Label>
                      <Button
                        type="button"
                        className="w-100"
                        disabled={(!!licensePlate && !!vin) || (!licensePlate && !vin)}
                        onClick={fetchFromTraficom}
                      >
                        <ActionSpinner pending={traficomPending} />
                        {t('agent-addVehicleModal-form-button-getFromTraficom')}
                      </Button>
                    </Form.Group>
                  </Col>
                </>
              )}
          </Row>
          <Row>
            <Col xs={4} sm={3} className="mb-3 form-same-row">
              <Form.Group>
                <Form.Label>{t('general-vehicle-vehicleType')}</Form.Label>
                <Form.Select value={vehicleType} onChange={(e) => setVehicleType(e.target.value)} required>
                  {Object.values(enums.VehicleType).map((vt) => (
                    <option value={vt} key={vt}>{vt}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={8} sm={4} className="mb-3">
              <Form.Group>
                <Form.Label>{t('general-vehicle-make')}</Form.Label>
                <Form.Control type="input" value={make} onChange={(e) => setMake(e.target.value)} required />
              </Form.Group>
            </Col>
            <Col xs={12} sm={5} className="mb-3">
              <Form.Group>
                <Form.Label>{t('general-vehicle-model')}</Form.Label>
                <Form.Control type="input" value={model} onChange={(e) => setModel(e.target.value)} required />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={7} className="mb-3">
              <Form.Group>
                <Form.Label>{t('general-vehicle-trim')}</Form.Label>
                <Form.Control type="input" value={trim} onChange={(e) => setTrim(e.target.value)} required />
              </Form.Group>
            </Col>
            <Col xs={12} sm={5} className="mb-3">
              <Form.Group>
                <Form.Label>{t('general-vehicle-color')}</Form.Label>
                <Form.Control type="input" value={color} onChange={(e) => setColor(e.target.value)} required />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={5} sm={3} className="mb-3 form-same-row">
              <Form.Group>
                <Form.Label>{t('general-vehicle-nextInspectionDate')}</Form.Label>
                <Form.Check
                  type="switch"
                  label={t('general-vehicle-nextInspectionDate-unknown')}
                  checked={nextInspectionDateUnknown}
                  onChange={(e) => setNextInspectionDateUnknown(e.target.checked)}
                />
              </Form.Group>
            </Col>
            <Col xs={7} sm={4} className="mb-3">
              <Form.Label>&nbsp;</Form.Label>
              <InputGroup>
                <InputGroup.Text className="border-primary border-end-0 bg-primary text-white flex-column justify-content-around align-items-center form-icon">
                  <FaCalendar />
                </InputGroup.Text>
                <Form.Control
                  className="border-primary border-start-0 text-center"
                  value={nextInspectionDate || ''}
                  type="date"
                  onChange={(e) => setNextInspectionDate(e.target.value)}
                  disabled={nextInspectionDateUnknown}
                  required={!nextInspectionDateUnknown}
                />
              </InputGroup>
            </Col>
            <Col xs={7} sm={3} className="mb-3 form-same-row">
              <Form.Group>
                <Form.Label>{t('general-vehicle-driversLicenseType')}</Form.Label>
                <Form.Select value={driversLicenseType} onChange={(e) => setDriversLicenseType(e.target.value)} required>
                  {Object.values(enums.DriversLicenseType).map((vt) => (
                    <option value={vt} key={vt}>{vt}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={5} sm={2} className="mb-3">
              <Form.Group>
                <Form.Label>{t('general-vehicle-enabled')}</Form.Label>
                <Form.Check
                  type="switch"
                  checked={!disabled}
                  onChange={(e) => setDisabled(!e.target.checked)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>{t('general-cancel')}</Button>
        <Button
          variant="primary"
          onClick={() => formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))}
          disabled={traficomPending || savePending}
        >
          <ActionSpinner pending={savePending} />
          <FaFloppyDisk className="me-2" />
          {t('general-save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// TODO: Fix constant inspection date and result

export default AddVehicleModal;
