/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import React, { ReactElement, useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import moment, { Moment } from 'moment';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { UserStatus } from '@koeajacom/ka-types/dist/types';

import CustomerAPI from '../../api/customer';
import TermsAndConditionsModal from '../../components/customer/TermsAndConditionsModal';

interface KoeajoProps {
  userStatus: UserStatus | null;
  api: CustomerAPI;
}

interface Colors {
  cardColor: string;
  textColor: string;
}

const Koeajo = ({
  userStatus,
  api,
}: KoeajoProps): ReactElement => {
  const [currentMoment, setCurrentMoment] = useState<Moment>(moment());
  const [intervalObj, setIntervalObj] = useState<number>();
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = useState<boolean>(false);

  const handleCloseModal = () => {
    setShowTermsAndConditionsModal(false);
  };

  const tick = () => setCurrentMoment(moment());

  useEffect(() => {
    setIntervalObj(window.setInterval(tick, 10000));

    return () => {
      window.clearInterval(intervalObj);
    };
  }, []);

  const { t } = useTranslation();

  if (!userStatus?.testDrivePermit) return <Navigate to="/" replace />;

  const tdp = userStatus.testDrivePermit;
  const p = userStatus.profile!;

  const running = tdp.actualStartDateTime && !tdp.actualEndDateTime;

  const diffMinutes = moment(tdp.endDateTime).diff(currentMoment, 'minutes');

  let colors: Colors = {
    cardColor: '',
    textColor: '',
  };

  if (running) {
    colors = {
      cardColor: 'success',
      textColor: 'white',
    };
  }

  if (running && diffMinutes < 10) {
    colors = {
      cardColor: 'warning',
      textColor: '',
    };
  }

  if (running && diffMinutes < 0) {
    colors = {
      cardColor: 'danger',
      textColor: 'white',
    };
  }

  return (
    <>
      <TermsAndConditionsModal
        handleClose={handleCloseModal}
        show={showTermsAndConditionsModal}
      />
      <Row className="justify-content-center py-2">
        <Col xs={12} md={10} lg={7} xxl={6}>
          <Card>
            <Card.Header className={`py-1 bg-${colors.cardColor}`} style={{ color: colors.textColor }}>
              <Row className="fs-4 fs-sm-4 fw-bold">
                <Col>
                  {t('testDrivePermit-title')}
                  <span className="float-end">
                    {running ? `${diffMinutes} min` : null}
                  </span>
                </Col>
              </Row>

              <Row className="mt-1 mt-sm-4 mt-md-5 fs-sm-5">
                <Col>
                  {tdp.actualStartDateTime
                    ? `${t('testDrivePermit-startedAt')}: ${moment(tdp.actualStartDateTime).format('DD.MM.YYYY HH:mm')}`
                    : `${t('testDrivePermit-startsAt')}: ${moment(tdp.startDateTime).format('DD.MM.YYYY HH:mm')}`}
                  <br />
                  {`${t('testDrivePermit-returnBy')}: ${moment(tdp.endDateTime).format('DD.MM.YYYY HH:mm')}`}
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col className="d-flex fs-2 fw-bold">
                  {tdp.licensePlate}
                </Col>
              </Row>
              <Row className="fs-5 mt-sm-4 mt-md-5 fs-sm-5">
                <Col>
                  {tdp.vehicleString}
                </Col>
              </Row>
              <Row className="mt-2 mt-sm-4 mt-md-5 fs-sm-5">
                <Col>
                  {tdp.trim}
                </Col>
              </Row>
              <Row className="mt-3 mt-sm-4 mt-md-5 fs-sm-5">
                <Col>
                  <b className="fs-5 mb-1 d-inline-block">{t('testDrivePermit-customerDetails')}</b>
                  <br />
                  <b>{`${p.firstName} ${p.lastName}`}</b>
                  <br />
                  {userStatus.hetu}
                  <br />
                  {p.phoneNumber}
                </Col>
              </Row>
              <Row className="mt-3 mt-sm-4 mt-md-5">
                <Col>
                  <b className="fs-5 mb-1 d-inline-block">{t('testDrivePermit-dealershipDetails')}</b>
                  <br />
                  <b>{tdp.dealership.name}</b>
                  <br />
                  {tdp.dealership.phoneNumber}
                  <br />
                  {t('general-agent')}
                  {': '}
                  {tdp.agentName || t('general-noAgent')}
                </Col>
              </Row>
              <Row className="mt-3 mt-sm-4 mt-md-5">
                <Col className="d-flex align-items-center">
                  <span className="fs-5 fw-bold">{`${t('general-deductible')}: ${tdp.deductible}€`}</span>
                  <Button
                    size="sm"
                    className="d-flex ms-3 fw-bold"
                    onClick={() => setShowTermsAndConditionsModal(true)}
                  >
                    {t('general-termsAndConditionsTitle')}
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Koeajo;
