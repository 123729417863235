/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import React, { ReactElement, useState, useEffect } from 'react';
import moment, { Moment } from 'moment';

import { types } from '@koeajacom/ka-types';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { FaPen, FaTrash } from 'react-icons/fa6';

import AgentAPI from '../../api/agent';

import ActionSpinner from '../common/ActionSpinner';
import ConfirmDeleteModal from './ConfirmDeleteModal';

interface VehicleCardProps {
  api: AgentAPI;
  v: types.DBVehicle;
  setVehicles: (vehicles: types.DBVehicle[]) => void;
  setVehicleToEdit: (vehicle: types.DBVehicle) => void;
}

const DATE_FORMAT = 'DD.MM.YYYY';

const VehicleCard = ({
  api,
  v,
  setVehicles,
  setVehicleToEdit,
}: VehicleCardProps): ReactElement => {
  const [pendingL, setPendingL] = useState<boolean>(false);
  const [pendingR, setPendingR] = useState<boolean>(false);
  const [aboutToDelete, setAboutToDelete] = useState<boolean>(false);

  const [currentMoment, setCurrentMoment] = useState<Moment>(moment());
  const [intervalObj, setIntervalObj] = useState<number>();

  const tick = () => setCurrentMoment(moment());

  const handleCloseModal = () => setAboutToDelete(false);

  useEffect(() => {
    setIntervalObj(window.setInterval(tick, 10000));

    return () => {
      window.clearInterval(intervalObj);
    };
  }, []);

  const { t } = useTranslation();

  const deleteVehicle = () => {
    setPendingR(true);
    handleCloseModal();
    api.deleteVehicle(v.ID).then((vs) => { setVehicles(vs.vehicles); setPendingR(false); })
      .catch(() => { setPendingR(false); });
  };

  return (
    <>
      <ConfirmDeleteModal
        show={aboutToDelete}
        title={t('agent-vehicles-confirmDeleteTitle')}
        body={(
          <>
            <b>
              {t('agent-vehicles-confirmDeleteBody')}
            </b>
            <br />
            <br />
            <b>{v.licensePlate}</b>
            <br />
            <b>
              {v.color}
              {' '}
              {v.make}
              {' '}
              {v.model}
            </b>
          </>
        )}
        handleClose={handleCloseModal}
        action={deleteVehicle}
      />
      <Card
        className="mb-2"
      >
        <Card.Header className="fs-5 fs-sm-4 fw-bold py-1 p-2">
          {v.licensePlate}
          {v.disabled ? ` - ${t('general-vehicle-disabled')}` : ''}
        </Card.Header>
        <Card.Body className="p-2">
          <Row className="mt-md-5 fs-sm-5">
            <Col>
              <b>
                {v.color}
                {' '}
                {v.make}
                {' '}
                {v.model}
              </b>
              <br />
              {moment(v.nextInspectionDate).isSameOrBefore(moment().add(3, 'months')) ? (
                <>
                  <b className="text-danger">
                    {t('agent-testDrivePermits-inspectionDue')}
                    {': '}
                    {moment(v.nextInspectionDate).format(DATE_FORMAT)}
                  </b>
                  <br />
                </>
              ) : null}
            </Col>
          </Row>
          <Row className="mt-2 mt-sm-4 mt-md-5 fs-sm-5">
            <Col xs={6}>
              <Button
                variant="primary"
                className="w-100"
                disabled={pendingL || pendingR}
                onClick={() => setVehicleToEdit(v)}
              >
                <ActionSpinner pending={pendingL} />
                <FaPen className="me-2" />
                {t('agent-vehicles-button-edit')}
              </Button>
            </Col>
            <Col xs={6} className="ps-0">
              <Button
                variant="danger"
                className="w-100"
                disabled={pendingL || pendingR}
                onClick={() => setAboutToDelete(true)}
              >
                <ActionSpinner pending={pendingR} />
                <FaTrash className="me-2" />
                {t('general-delete')}
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default VehicleCard;
